import { ChannelType } from './ChannelType'
import { Channel } from './Channel'
import { AutoAnswer } from './AutoAnswer'
import { CALL_TO_ACTION_TYPE } from '@/models/whatsapp-editor/enums/CallToActionType'
import { RESPONSE_TYPE } from '@/models/whatsapp-editor/enums/AutomaticResponseType'
import { CALL_TO_ACTION } from '@/models/whatsapp-editor/enums/CallToAction'
import { AUTOMATIC_RESPONSE } from '@/models/whatsapp-editor/enums/AutomaticResponse'
import HeadBoardTypeAuth from '@/models/whatsapp-editor/HeadBoardTypeAuth.js'

export class ChannelWhatsapp extends Channel {
  id = null
  type = ChannelType.WHATSAPP_ID
  sender = ''
  encoding = 'whatsapp'
  message = ''
  certified = 0
  certified2way = 0
  flash = 0
  bookmark = 0
  templateStructure = ''
  attachments = []
  clickable = false
  headboardType = {
    TEXT: 1,
    HEADER_TEXT: 2,
    CALL_ACTION_TEXT: 3,
    CALL_ACTION_HEADER_TEXT: 4,
    RESPONSE_TEXT: 5,
    RESPONSE_HEADER_TEXT: 6,
    CALL_ACTION_TEXT_AUTH: 7,
    OPTION_TEXT: 8,
    OPTION_HEADER_TEXT: 9,
  }

  selectedHeadboardType = 1
  headboard = ''
  headboardOptions = [
    { value: 1, text: 'Imagen' },
    { value: 2, text: 'Texto' },
    { value: 3, text: 'Video' },
    { value: 4, text: 'Archivo adjunto' },
    { value: 5, text: 'Localización' },
  ]

  callToActionType = CALL_TO_ACTION_TYPE
  responseType = RESPONSE_TYPE
  callToActionOptions = CALL_TO_ACTION
  automaticResponseOptions = AUTOMATIC_RESPONSE

  selectedHeadboardOption = 1
  footerPage = ''
  callActionUrlText = 'Abrir enlace'
  callActionUrl = 'https://example.com'
  callActionUrlActive = true
  callActionPhoneText = 'Llamar teléfono'
  callActionPhone = ''
  callActionPhoneActive = false
  callActionCountryPrefix = 'ES'

  automaticResponseColOptions = [
    { value: 1, text: '100%' },
    { value: 2, text: '50%' },
  ]

  automaticResponses = []
  callToActionResponses = []

  template = ''
  templateWhatsappId = null
  hasAutoAnswer = false
  autoAnswer = [
    new AutoAnswer(),
  ]

  localizationData = {
    businessName: null,
    businessAddress: null,
    lat: null,
    long: null,
  }

  customVerificationCodeField = null

  hasChatbot = null
  chatbotId = null

  status = 1
  path = 'whatsapp'
  headBoardTypeAuth = new HeadBoardTypeAuth()
  whatsappWabaId = null
  customFieldsExampleValues = {
      header: '',
      body: [],
      button: '',
  }

  constructor (data) {
    super()
    this.loadData(data)
  }

  loadData (data) {
    if (data) {
      this.id = data.id
      this.sender = data.sender
      this.message = data.message
      this.selectedHeadboardType = data.head_board_type
      this.selectedHeadboardOption = data.head_board_option
      this.headboard = JSON.parse(data.head_board)
      this.footerPage = data.footer_page
      this.selectedCallToActionOption = data.call_to_action_option
      this.callActionUrlText = data.call_action_url_text
      this.callActionUrl = data.call_action_url
      this.callActionUrlActive = data.call_action_url_active
      this.callActionPhoneText = data.call_action_phone_text
      this.callActionCountryPrefix = data.call_action_country_prefix
      this.callActionPhone = data.call_action_phone
      this.callActionPhoneActive = data.call_action_phone_active
      this.automaticResponses = JSON.parse(data.automatic_responses)
      this.callToActionResponses = JSON.parse(data.call_to_action_responses)
      this.templateWhatsappId = data.template_whatsapp_id
      this.template = data.template ? JSON.parse(data.template) : ''
      this.hasAutoAnswer = true ?? false
      if (data.auto_answer) {
        this.autoAnswer = []
        const autoAnswers = JSON.parse(data.auto_answer)
        autoAnswers.forEach(answer => {
          this.autoAnswer.push(new AutoAnswer(answer))
        })
      }
      this.localizationData = data.head_board ? JSON.parse(data.head_board) : this.localizationData
      this.customVerificationCodeField = data.custom_verification_code_field
      this.hasChatbot = data.has_chatbot
      this.chatbotId = data.chatbot_id
    }
  }
}
