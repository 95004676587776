import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import EventBus from '@/util/EventBus'
import FormsService from '@/services/forms.service.js'
import VueApexCharts from 'vue-apexcharts'
import getEnv from '@/util/env'

export default {
  name: 'FormStats',
  components: {
    HeaderTopDashboard,
    apexchart: VueApexCharts,
  },
  props: ['selectedFormId', 'selectedSendingId'],
  data () {
    return {
      forms: [],
      form: null,
      tab: 'resumen',
      items: ['resumen', 'preguntas', 'individual'],
      page: 1,
      total: 0,
      individualForm: {},
      readyCharts: false,
      campaigns: [],
      reDraw: true,
    }
  },
  watch: {
    page () {
      this.getFormAnsweredPagination()
    },
  },
  methods: {

    userLocaleMonthAndYear (dateAndMonth) {
      const language = navigator.languages[0]
      const parts = dateAndMonth.split('-')
      const date = new Date()
      date.setMonth(parseInt(parts[1]))
      let month = date.toLocaleString(language, { month: 'short' })
      month = month.replace('.', '')
      return month[0].toUpperCase() + month.slice(1) + ' ' + parts[0]
    },

    setFormattedDatesAnswers (question) {
      const result = {}
      for (const key in question.answers) {
        const parts = key.split('-')
        const dateYearAndMonth = parts[0] + '-' + parts[1]
        if (Object.hasOwnProperty.call(result, dateYearAndMonth)) {
          result[dateYearAndMonth].push({ text: parts[2], count: question.answers[key].length })
        } else {
          result[dateYearAndMonth] = [{ text: parts[2], count: question.answers[key].length }]
        }
      }
      question.formattedAnswers = result
    },

    setFormattedTimesAnswers (question) {
      const result = {}
      for (const key in question.answers) {
        const parts = key.split(':')
        const keyPart = parts[0] + ' : __'
        if (Object.hasOwnProperty.call(result, keyPart)) {
          result[keyPart].push({ text: key, count: question.answers[key].length })
        } else {
          result[keyPart] = [{ text: key, count: question.answers[key].length }]
        }
      }
      question.formattedAnswers = result
    },

    reDrawPage () {
      this.reDraw = false
      setTimeout(() => {
        this.reDraw = true
      })
    },
    getBarChartOptions (question) {
      return {
        series: [{
          data: question.stats.counts,
        }],
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: ['checkbox'].includes(question.type),
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            const total = opts.w.globals.series[opts.seriesIndex].reduce((a, b) => a + b, 0)
            const percentage = ((opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] / total) * 100).toFixed(0)
            return percentage + '%'
          },
          offsetX: 30,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        xaxis: {
          categories: question.stats.labels,
        },
                tooltip: {
          y: {
            title: {
              formatter: function (value) {
                return 'Respuestas'
              },
            },
          },
        },
      }
    },
    // setForms () {
    //   FormsService.getFormsList().then((data) => {
    //     this.forms = data.forms
    //   })
    // },
    // getCampainsFromFormId () {
    //   this.selectedSendingId = null
    //   FormsService.getCampainsFromFormId(this.selectedFormId).then((data) => {
    //     this.campaigns = data.campaigns
    //   })
    // },

    getAnsweredForm () {
      this.readyCharts = false
      this.form = null
      FormsService.getAnsweredForm(this.selectedFormId, this.selectedSendingId).then((data) => {
        if (!data.form) {
          return
        }
        this.form = data.form
        this.form.questions.forEach((question, index) => {
          const labels = Object.keys(question.answers)
          const counts = []

          for (const key in question.answers) {
            counts.push(question.answers[key].length)
          }
          this.form.questions[index].stats = { labels: labels, counts: counts }

          if (this.form.questions[index].type === 'date') {
            this.setFormattedDatesAnswers(this.form.questions[index])
          }
          if (this.form.questions[index].type === 'time') {
            this.setFormattedTimesAnswers(this.form.questions[index])
          }
        })
        this.readyCharts = true

        this.getFormAnsweredPagination()
      })
    },

    getFormAnsweredPagination () {
      FormsService.getFormAnsweredPagination(this.page, this.selectedFormId, this.selectedSendingId).then((data) => {
        this.total = data.total
        this.individualForm = data.form
      })
    },

    downloadFormAnswers () {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('backend_endpoint') + 'api/forms/export-form-answers-csv/' + this.form.formId + '/' + this.selectedSendingId + '?token=' + user.token
      window.location.href = url
    },

   isObject (val) {
      if (val === null) { return false }
      return ((typeof val === 'function') || (typeof val === 'object'))
    },
  },
  created () {
    this.getAnsweredForm()
    // this.setForms()
  },

  mounted () {
    EventBus.$on('downloadFormAnswersEvent', this.downloadFormAnswers)
  },
}
